<template>
  <div
    v-if="task"
    class="rounded-lg overflow-hidden group relative px-4 py-2 flex gap-1 border-b hover:bg-white"
    :class="{
      'bg-gray-300 bg-opacity-20 shadow-none hover:shadow': !isActive,
      'bg-white shadow': isActive,
    }"
    @click="$emit('open', task.id)"
  >
    <div class="flex-1 flex flex-col gap-1">
      <div class="flex items-center justify-between text-gray-500 gap-1">
        <div class="font-medium text-xs">
          {{ task.id.toUpperCase() }}
          <span v-if="task.user_key != loggedUser.key" class="ml-2 text-black">{{ $t(`participant`) }}</span>
        </div>

        <div class="flex gap-3">
          <div class="text-xs text-gray-500">{{ task.created_at | humanDateTimePT }}</div>
          <div class="flex items-center">
            <fw-dot inline :color="isNew ? 'primary' : 'light'" :ping="isNew" :class="{ 'opacity-60': !isNew }" />
          </div>
        </div>
      </div>
      <div class="flex items-center gap-3">
        <div class="h-11 flex-1">
          <v-clamp
            class="leading-5 text-gray-800"
            :class="{ 'font-semibold': isNew, 'font-normal': !isNew }"
            :max-lines="2"
            >{{ task.title }}</v-clamp
          >
        </div>
        <div>
          <fw-tag :type="statusTagColor[task.status]" size="xs" class="w-16">{{ $t(`status.${task.status}`) }}</fw-tag>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    task: {
      type: Object,
      default: () => {},
    },
    active: {
      type: Boolean,
      default: false,
    },
    showRequester: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      statusTagColor: {
        new: 'primary',
        open: 'light-orange',
        closed: 'light-gray',
      },
    }
  },

  computed: {
    isNew() {
      return this.task.status === 'new'
    },
    isActive() {
      return this.$route.params.key === this.task.id || this.active
    },
    loggedUser() {
      return this.$store.getters.getUser
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "status": {
      "new": "Novo",
      "open": "Aberto",
      "closed": "Fechado"
    },
    "participant": "Participante"
  },
  "en": {
    "status": {
      "new": "New",
      "open": "Open",
      "closed": "Closed"
    },
    "participant": "Participant"
  }
}
</i18n>
